// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrlApi: 'https://incentivados-gdm.jelastic.saveincloud.net',
  versionApi: 'v1',
  GoogleMapsAPIKEY: 'AIzaSyDvnUrSMUaxtPyoAc9jsdtZAFgbn1Uc2Ag',
  linkAccepTerms: 'https://incentivados-gdm-storage.s3.sa-east-1.amazonaws.com/v1_GDM_Termos_de_Uso_da_Plataforma_Incentivados_25-08-2023.pdf',
  linkPrivatePolicy: 'https://incentivados-gdm-storage.s3.sa-east-1.amazonaws.com/v1_GDM_Poli%CC%81ticas_de_Privacidade_25-08-2023+(1).pdf'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
