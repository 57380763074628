import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard } from '@core/guards/auth.guard';

// Components
// import { HomePageComponent } from '@pages/home-page/home-page.component';
import { loginPageGdmComponent } from '@pages/login-page-gdm/login-page-gdm.component';
import { DefaultTemplateComponent } from '@pages/painel-page/default-template/default-template.component';
import { ProjectsAddComponent } from './pages/painel-page/projects/projects-add/projects-add.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: loginPageGdmComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'painel',
    component: DefaultTemplateComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('@pages/painel-page/painel.module').then(
      m => m.PainelModule
    )
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
